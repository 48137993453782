import 'slick-carousel'
import 'magnific-popup'
import '@selectize/selectize'
import "external-svg-loader"
import initSelectize from '../components/select/select'
import './lib/building-blocks'

import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'

initGreenSock()
// initHeadroom()
initMobileNav()
initA11yFunctions()
initSelectize()
