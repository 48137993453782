import $ from 'jquery'
import initSelectize from '../../components/select/select'

// $(document).on('click', '.js-read-bio', function(e) {
//   e.preventDefault()
//   $(e.target).parent().find('+ div').slideToggle()
// })
const form_selectors = ['.donation-form', '.email-registration-form']

var formatForm = function() {
  if ( $('.btn-group input[type=submit]').length && $('.email-registration-form').length && $('.contact').length ) {
    $('.email-registration-form .contact').append($('.email-registration-form .btn-group'))
    $('.email-registration-form .btn-group input[type=submit]').val('Sign Up')
  }
  
  form_selectors.forEach(selector => {
    $(selector).length && $(`${selector} label`).each(function() {
      const text = $(this).text()
      const $input = $(this).find('+ :input')
      
      if($input.is('select')) {
        initSelectize()
        $input.find('option:first-child').text(text)
      } 
      
      $input.attr('placeholder', text);

    })
  })
}

let bloomberandFormFormat = setInterval(function( ){
  if (typeof(Bloomerang) === 'undefined' || !Bloomerang._isReady) {
  } else {
    setTimeout(() => {
      formatForm()
      clearInterval(bloomberandFormFormat)
      console.info('Bloomerang form formatted')
      form_selectors.forEach((selector) => $(selector).addClass('is-shown'))
      console.info('Bloomerang form displayed')
    }, 550)
  }
}, 600)


