import $ from 'jquery'

export default function initSelectize () {
  $(function () {
      $("select").selectize({
          onInitialize: function() {
              this.$control_input.attr('readonly', true);
        }
      }
    );
  });
}
